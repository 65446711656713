import React from 'react';

import Layout from '../components/Layout';

import female from '../assets/images/female.png';
import male from '../assets/images/male.png';
import kaing_su_wai from '../assets/images/khine_su_wai.png';
import khin_mya_thwin from '../assets/images/khin_mya_thwin.png';
import htet_htet_aung from '../assets/images/htet_htet_aung.png';
import nang_ei_mon from '../assets/images/nang_ei_mon.png';
import swe_zin_oo from '../assets/images/swe_zin_oo.png';
import san_san from '../assets/images/san_san.png';
import sithu_kyaw from '../assets/images/sithu_kyaw.png';
import hsu_wai from '../assets/images/hsu_wai.png';
import chue_ei_mon from '../assets/images/chue_ei_mon.png';
import hinn_hinn_lwin from '../assets/images/hinn_hinn_lwin.png';
import waddy from '../assets/images/waddy.png';
import min from '../assets/img/min.png';
import myat_noe from '../assets/images/myat_noe.png';
import paing_su_san from '../assets/images/paing_su_san.png';
import zarchi_win from '../assets/images/zarchi_win.png';
import ye_myint_oo from '../assets/images/ye_myint_oo.png';
import may_ko from '../assets/images/may_ko.png';

import config from '../../config';
const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <div className="logo">
          <span className="icon">
            <img src={min} className="img" alt="" />
          </span>
        </div>
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
      </div>
    </section>

    <section id="wrapper" >
      <header>
        <div className="inner">
          <h2>About Me</h2>
          <p>Min is a Financial Architect who focuses on holistic Financial
Planning to serve the Burmese community in Singapore.</p>
        </div>
      </header>
      <div className="wrapper">        
        <div className="inner">
          <h3 className="major">MY STORY</h3>
          <p>
          Min is a Financial Architect who focuses on holistic Financial Planning to serve the burmese community in Singapore.
          I pursued the financial service industry after I graduated from school. Being in this Industry, reconnected me with my roots, cultures and the Burmese community in Singapore. I feel it is extremely important for us to reconnect with our community when we are overseas. So that we can help each other in tough times and celebrate the good news.
          </p>

          <h3 className="major">My Inspiration to connect with the growing Burmese community in Singapore</h3>
          <p>
          I feel that everyone should have two types of Education - Own Professional Education and Financial Education. I strongly feel that our people thrive well in their Professional Education and Career. However, many are neglecting the strong importance of Financial Education.  
          I want to serve and educate the Burmese community in Singapore with abundant Financial Knowledge such as Emergency cash account and Growth Account.
          </p>
          <p>
          Apart from sharing about financial education, having lived in Singapore for many years, 
          I have come to realize that some of us came here alone and we don’t 
          have many family members or many friends here in Singapore. 
          We became very independent and that actually led to some of us being lonely. 
          We only look forward to our job and salary. Somehow in our lives we became very robotic. 
          I strongly believe that life itself has more meaning than just work and salary.
          </p>
          <p>
          Hence, I would like to create a wonderful connection among the burmese community. 
          Connecting everyone together so that we all can come together as a community to participate in different activities. Within the community, 
          I hope we all can learn from one another and we all grow together as a community.
          </p>

          <h3 className="major">My Vision within this growing Burmese community in Singapore</h3>
          <p>
              <ul>
                <li>To Connect with 20,000 out of 200,000 Burmese Individuals working in Singapore</li>
                <li>To make sure these 20,000 of them are well adequate with their financial knowledge</li>
                <li>To make sure these 20,000 of them feel warm, welcome, enjoy life and grow together.</li>
              </ul>
          </p>

          <h3 className="major">Contact Information</h3>
          <p>
          <ul className="contact" >
            <li className="fa-phone" style={{display: 'inline',paddingTop:'8px',font:'inherit'}}>{config.phone}</li> 
            <li style={{display: 'inline'}}></li>
            <li className="fa fa-instagram" style={{display: 'inline',paddingTop:'8px',font:'inherit'}}>              
              <a href={config.instagram}>_10.min</a>            
            </li>    
            <li style={{display: 'inline'}}></li>     
            <li className="fa fa-linkedin-square" style={{display: 'inline',paddingTop:'8px',font:'inherit'}}>              
              <a href={config.linkedin}>Min (Min) Khant</a>            
            </li>              
          </ul>
          
          
          </p>
        </div>
      </div>
    </section>  

    <div className="wrapper">

    <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={myat_noe} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
              MYAT NOE
              <br />
              <span>BANK OFFICER</span>
            </h2>
            <p>
            Min Khant explains the services that he will provide and the process of planning and documentation
            As a professional, I like about your planning process like the way you gather all necessary
            information and documents, clarify my current financial status before giving the advice that I
            need. He explain the issues and share the concepts related to the overall financial planning process
            that are appropriate for me.
           <br/>I am really pleasant with your service. It is really great to work with a financial consultant who
            is truly passionate & knowledgeable in his job, and who is interested in his clients needs,
            situations, & preferences. That’s why I would highly recommend you to anyone who wants to
            achieve better financial outcomes
            </p>
          </div>
        </div>
      </section>


      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={paing_su_san} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
              PAING SU SAN
              <br />
              <span>SOFTWARE ENGINEER</span>
            </h2>
            <p>
            I just wanted to take a moment to express my gratitude for all your help and support as both
            my insurance advisor and friend. Your knowledge, professionalism and genuine care for my
            well-being have made a significant impact on my life.
           <br/>You always go above and beyond to ensure I have the coverage I need, and I appreciate your
            timely response to my questions and concerns. It's a true blessing to have you in my corner.
            Thank you for your exceptional service and attention to detail in providing me with insurance
            coverage. Your professionalism, knowledge and timely follow-up have been greatly appreciated.
            <br/>
            I highly recommend your services to anyone in need of insurance services and seeking a
            trusted advisor in the insurance industry. Thank you again for being such a fantastic person. Keep up the good work!

            </p>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={zarchi_win} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
            ZARCHI WIN
              <br />
              <span>ENGINEER</span>
            </h2>
            <p>
            I prefer the life insurance plan with saving among others as i consider health is the 1st priority and important to us.
           <br/>Min Khant, not only can work as professional but also good personality with nice visual. 
           <br/>
           I believe you can advise well and it is acceptable
           Realise the income and outcome process including health matter.

            </p>
          </div>
        </div>
      </section>

      <section id="one" className="wrapper spotlight style3">
        <div className="inner">
          <a href="/#" className="image">
            <img src={ye_myint_oo} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
            YE MYINT OO
              <br />
              <span>SOFTWARE ENGINEER</span>
            </h2>
            <p>
            Min explained the emergency funding in crystal clear. He went through the
            entire planning and gave the best for me.
           <br/>I recommend Min to other people who want to do insurance. He is more than a
            professional and polite and friendly is his nature.

            </p>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={may_ko} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
            MAY KO
              <br />
              <span>SOFTWARE ENGINEER</span>
            </h2>
            <p>
            Min khant took effort in explaining the principles,comparing various products.
            He was extremely patient with my doubts and queries.
           <br/>I knew him through a mutual friend and he is quite friendly.He always listens
            carefully and makes recommendations that suits my constraints and
            situations rather than selling the product.
            <br/>
            I have no prior investment experience, and I came across Min Khant who
            introduced me to the idea of having proper financial planning. He has
            broadened my financial knowledge and I am more than pleased with his
            service, so would recommend to my friends.


            </p>
          </div>
        </div>
      </section>

    <section id="three" className="wrapper spotlight style3">
        <div className="inner">
          <a href="/#" className="image">
            <img src={khin_mya_thwin} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
              Khin Mya Thwin & U Thu Ya
              <br />
              <span>50</span> | <span>Accountant</span>
            </h2>
            <p>
              Thank you for getting to know about insurance, and for his
              patience in explaining all the opportunities, so I contacted Min
              Khant and entered the insurances. Insurance အကြောင်းတွေသေချာ
              သိခွင့် ရခဲ့တဲ့အတွက် ကျေးဇူးတင်ပါတယ် သူ့ရဲ့ စိတ်ရှည်စွာည်စွာ
              ရှင်းပြပေး တဲ့အတွက်လည်း အခွင့်အင့် ရေးတွေ အား လုံး တိတိကျ ကျ သိ
              ခွင့်ရင့်တယ်တဲ့အတွက် Min Khant နဲ့ဆက်သွယ်ပြီး insurancesဝင်ခဲ့ပါ တ
            </p>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={hinn_hinn_lwin} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
              Hinn Hinn Lwin
              <br />
              <span>Accountant</span>
            </h2>
            <p>
              I really like this planning Min Khant did for me, which give the
              benefit not only for insured coverage but also cash value as like
              the investment. Accountant As you choose the best plan for me, I
              would like to say thanks you. Appreciate for your service!.
            </p>
          </div>
        </div>
      </section>

      <section id="three" className="wrapper spotlight style3">
        <div className="inner">
          <a href="/#" className="image">
            <img src={waddy} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
              Waddy
              <br />
              <span>IT Analyst</span>
            </h2>
            <p>
              Min Khant is a capable financial advisor. He genuinely cares about
              his customer, and he explained everything to them in a clear and
              sameless manner. He guided me through the entire financial
              planning process, analyzed my account, and recommended the best
              course of action
            </p>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={chue_ei_mon} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
              CHUE EIMON
              <br />
              <span>Engineer</span>
            </h2>
            <p>
              'Insurance? First thing comes to my mind when i heard Insurance is
              "Is it necessary? Is it a waste of money ?" All of my thoughts
              changed after I met with Min Khant. He explained to me about
              advantages , benefits and policy of insurance .He is really a good
              friend and also good adviser . I would like to say Thanks to AIA
              and Min Khant.
            </p>
          </div>
        </div>
      </section>

      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={nang_ei_mon} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
              Nang Ei Mon
              <br />
              <span>28</span> | <span>Engineer</span>
            </h2>
            <p>
              Planning with Min was smooth and easy as a breeze. He did not rush
              anything and took time to explain things rather thoroughly and
              also provided in layman termsfor some of the more complicated
              bits. It helped me understand what I really needed in finicial
              plainning
              <br />
              Min is candid and transparent when it comes to assessing and
              propsoing the right insurance for his clients. The way he
              introduces himself and communicates shows me that hes highly
              passionate about his work and makes me feel confident to approach
              him for anything I'm unsure of or want to know with the process.
              <br />
              I wold definitely recommend Min to any of my friends or
              qcquaitances looking to get finacial insurance or upgrade whatever
              they have to the moment for a finer financial scheme and brilliant
              service.
              <br />
              Min provied me with the variaous options and possibilites to grow
              and protect my fiance. I've gained knowledgeable insights for a
              better finace plainning. I feel assured and eager to move forward
              with Min as my financial plainnig agent. Thank You!
            </p>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={swe_zin_oo} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
              Swe Zin Oo
              <br />
              <span></span>
            </h2>
            <p>
              What you like about the planning process -I like him initiating
              everything start from beginning. I was totally unfamiliar with
              insurance and he first introduce me about insurance just brief for
              first meeting. And then start from that he slowly explain about
              that step by step so I am not confuse and overwhelm by insurance.
              What you like about me as a professional and a friend. -I like him
              as knowing very well about his job and so passionate about it. As
              a friend, I find it he is friendly, honest, active and social
              person. Why you would recommend me to your friends when it comes
              to financial planning? -I think he has very good knowledge about
              insurance and he can initiate and share about financial plan very
              well based on different pro-folio and situation.How have I value
              added to your knowledge in terms of financial planning. -I am
              aware that I have less worry about my money when it come to my
              health problems if I have good financial plan
            </p>
          </div>
        </div>
      </section>

      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={female} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
              Htwe Ei Ei Lwin
              <br />
              <span>Accountant</span>
            </h2>
            <p>
              Min Khant have managed to make the entire planning process smooth
              and easy to understand. He is very professional in advising me
              with different products that would fits my needs in the area
              perfectly I would recommend him as he explained to me the product
              clearly and even made nomination for my life policy as a form of
              service The take away I have from him was his emergency cash
              account concept which I could relate and understand.
            </p>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={san_san} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
              San San
              <br />
              <span>31</span> | <span>Accountant</span>
            </h2>
            <p>
              I would like to express my gratitude for Min Khant for my
              insurance planning. Before hand, I did not know much about
              healthcare insurance ,after talking with Min Khant I have gain
              more knowledge about finance and healthcare insurance Thanks to
              his recommendations as a friend and explaining in details about
              issues while being very professional. I would definitely recommend
              Min khant and AIA to other people as it was convenient and
              pleasant experience.
            </p>
          </div>
        </div>
      </section>

      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={sithu_kyaw} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
              Sithu Kyaw
              <br />
              <span>29</span> | <span>IT Analyst</span>
            </h2>
            <p>
              Min looks at overall aspect of client existing portfolios. Make
              summary view of what is already present and what areas could be
              further enhanced. Min is sincere and respectful advisor. He is
              also professional, cool and easygoing as a friend. Min truly care
              aboutthe well-being and have life stability of clients. Having the
              right mindset and the right approach positive outcomes attained
              for both parties in financial planning. Min's understanding of
              individual client preferences and needs. Being able to suggest
              customise recommendations allows me to know more about
              differentfinancial planning products and their purposes.
            </p>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={female} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
              Lily Aung
              <br />
              <span>Software Engineer</span>
            </h2>
            <p>
              Min Khant is very polite, friendly, and helpful. He was very
              informative on the different insurance plans and explained those
              in detail. He has been responsive to my requests, explained all my
              options, and made the experience stress-free. It's been great
              working with him on all my insurance needs. I have been thoroughly
              impressed with Min Khant as my agent and I am very grateful for
              all his help. One take away I have from him was his emergency cash
              account concept which I could relate and understand.
            </p>
          </div>
        </div>
      </section>

      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={kaing_su_wai} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
              Khine Su Wai
              <br />
              <span>28</span> | <span>Engineer</span>
            </h2>
            <p>
              The whole process was informative and easy to understand. Min
              patiently explains the new and difficult terms in bite sizes for
              me to understand and make an informed decision.He responds all my
              questions promptly. I can see that he’s passionate about what he’s
              doing and able to tailor the products according to clients’ needs.
              I would recommend him if you would like to have a chat and explore
              on how you can improve managing finances and plans for the future.
            </p>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={htet_htet_aung} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
              Htet Htet Aung
              <br />
              <span>Accountant</span>
            </h2>
            <p>
              Min Khant is awesome insurance agent that we have ever meet. He
              assisted us with the changing from other insurance company to AIA.
              He also advised the useful plans for my mom and dad.Not only he go
              extra mile to help change the CPF usage from my own account
              instead of my father but also he gave really good advices for
              suitable plans. While he was helping us with the changing and
              buying accident plan, he made effort and came down to our house
              eventhough it's late and explained us clearly. He is very
              hardworking, kind and understanding person.That's why I
              recommended him on my Facebook page and keep refering to my
              friends and relatives.
            </p>
          </div>
        </div>
      </section>

      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={hsu_wai} alt="" />
          </a>
          <div className="content">
            <h2 className="major">
              Hsu Wai
              <br />
              <span>Software Engineer</span>
            </h2>
            <p>
              Before I met Min khant, I wasn’t the type of the person who was
              very engaged in financial planning and investments. He is
              professional, and his explanation impressed me of the importance
              to have an emergecy cash acoount sine our first meeting. It also
              helped me to realise what I needed in terms of financial planning.
              Min Khant is someone I would strongly recommed to anyone since he
              is very patient and professional when it comes to assessing and
              proposing the best financial palns. He was able to provide a
              detailed assessment of my financial needs and retirement goals in
              just three consultations. He presented a variiety of options of
              acheving them, explaning each option of how iit is good for me. He
              also gave me enough time to think about the optiona and wasn’t
              pushy at all. Overall he helped the entire planning preocess go
              very smoothly.
            </p>
          </div>
        </div>
      </section>    

    
    </div>
  </Layout>
);

export default IndexPage;
